import React from 'react'
import {ModalWindow, ImageSelector} from '@ses-education/courses-components';
import { Button, Grid, Tab, Tabs, TextField } from '@material-ui/core';
import ProgramFormProperties from './program-form-properties';
import { Cancel, Check } from '@material-ui/icons';
import ProgramFormCourses from './program-form-courses';
// import ProgramFormTranslations from './program-form-translations';

const defaultProgram = {
    program_id: null,
    title: "",
    description: "",
    images: [],
    course_ids: []

}
const tabs = [
    {id: "properties", title: "Properties"},
    {id: "courses", title: "Courses"},
    // {id: "translations", title: "Translations"}
]

const  TabPanel = props => {
    const { children, value, index, tabname, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`${tabname}-panel-${index}`}
        aria-labelledby={`${tabname}-tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }


class ProgramForm extends React.Component{

    state = {
        program: {...defaultProgram},
        original: {...defaultProgram},
        isChanged: false,
        selectedTab: 0
    }

    componentDidMount(){
        this.populateForm();
    }

  populateForm( prevProps ) {
    const { program } = this.props;

    // overwrite default program with values from props
    this.setState(
      { 
        program: { ...defaultProgram, ...program }, 
        original: { ...defaultProgram, ...program },

        selectedTab: 
          // if program id has changed ... 
          (prevProps && prevProps?.program?.program_id !== program.program_id) ? 
          // ...then reset tab
          0 : 
          // ...otherwise - preserve current tab
          this.state.selectedTab,
          
        isChanged: false
      }); 
  }

    componentDidUpdate(prevProps){
        const {program } = this.props;
        if( JSON.stringify(prevProps?.program) !== JSON.stringify(program)){
            // pass prevProps to check program id
            this.populateForm(prevProps);
        }
    }

    /**
     * TODO: create a form validator here
     * @param {*} program 
     * @returns 
     */
    validateProgram = (program) => {
      const {showMessage} = this.props;
      if( program.title.replace(" ", "").length < 2 ){
        showMessage("Program title must be at least 2 characters long", "error");
        return false;
      }

      return true;
    };

    onClickOk = () => {
      const {onSave = console.log } = this.props;
      const {program} = this.state;
      if( !this.validateProgram(program) ){ 
          return false;
      }

      onSave(program);
    }

    onChangeProperties = (value) => {
      console.debug("onChangeProperties:", value);
      const {title, description} = value;
      this.updateProgramValues({title, description});
    }
    onChangeCourses = (value) => {
      console.debug("onChangeCourses:", value);
      const {course_ids} = value;
      
      this.updateProgramValues({course_ids});
    }
    
    onChangeTranslations = (value) => {
      
    }

    
  
    updateProgramValues = (values) => {
      console.debug("updateProgramValues", values);
      let program = {...this.state.program, ...values};
      
      const isChanged = JSON.stringify(this.state.original) !== JSON.stringify(program);

      console.debug("isChanged:", isChanged,  this.state.original, program);
      console.debug("updated program:", program);

      this.setState({program, isChanged });
    }

    render(){
        const {
            program,
            isChanged,
            selectedTab

        } = this.state;

        console.debug("state:", this.state);

        const {onClose, allCourses= [], onSelectImage, onDeleteImage, onUpdateImage} = this.props;
        const isNew = !program.program_id;

        // const { images } = program || {};
        // const image = Array.isArray(images) && images.length > 0 && images[0] && `${process.env.REACT_APP_MEDIA_URL}${images[0]}`;
        
        return ( <ModalWindow
            open={true}
            header={isNew ? "Add program" : "Edit program"}
            onClose={onClose}
        >   
            {!isNew && <Tabs value={selectedTab} onChange={(event, selectedTab)=>{
                        this.setState({selectedTab});
                      }}>
                    {tabs.map( (t, ind) => <Tab label={t.title} index={ind} />)}
                </Tabs>}
                <div className="align-items-stretch align-self-stretch column flex tabs-content m-y-12">
                    <TabPanel value={selectedTab} index={0} tabname="Properties">
                        <ProgramFormProperties {...{program}} 
                        onChange={this.onChangeProperties} 
                        onSelectImage={onSelectImage}
                        onDeleteImage={onDeleteImage}
                        onUpdateImage={onUpdateImage}
                        />
                    </TabPanel>
                    { 
                    !isNew && (<>
                    <TabPanel value={selectedTab} index={1} tabname="Courses">
                      <ProgramFormCourses {...{program, allCourses}} onChange={this.onChangeCourses} />
                    </TabPanel>
                    {/* <TabPanel value={selectedTab} index={2} tabname="Translations">
                      <ProgramFormTranslations {...{program}} onChange={this.onChangeTranslations} />
                    </TabPanel> */}
                    </>
                    ) 
                    }
                </div>
            <div className="align-self-stretch flex justify-content-space-between row">
                <Button disabled={!isChanged} variant="contained" color="primary" onClick={this.onClickOk}><Check/> {isNew ? "Add" : "Update"}</Button>
                <Button variant="contained" color="secondary" onClick={onClose}><Cancel/> Close</Button>
            </div>
        </ModalWindow>
        )
    }
}

export default ProgramForm;