import React from 'react'
import {ModalWindow, ConfirmDialog} from '@ses-education/courses-components';
import ProgramImageSelect from './program-image-select';
import { Button, IconButton } from '@material-ui/core';
import ProgramService from '../../../../services/programs';
import { Delete } from '@material-ui/icons';
const {REACT_APP_MEDIA_URL} = process.env;

class ProgramImageSelector extends React.Component{
    state={
        modalOpen: false,
        selected: null,
        images: null,
        confirmData: null
    }

    componentDidMount(){
        this.fetchImages();
    }

    async fetchImages( reload = false ){
        const images = await ProgramService.getImages( reload );
        this.setState({images});
    }

    onImageSelected =  (selected) => {
        this.setState({selected})
    }

    onImageUpdate = async () => {
        if( !this.state.selected ) return;
        
        const{onUpdateImage} = this.props;
        if( await onUpdateImage(this.state.selected) ){
            this.setState({modalOpen: false})
        }
        
    }

    render(){
        const {image, defaultImage, onSelect} = this.props;
        const {modalOpen, selected, images, confirmData} = this.state;

        return(
            <>
            <div className='image-selector'
            onClick={(ev)=> {
                ev.stopPropagation();
                this.setState({modalOpen: true})}
            }
            >
                <div className='display'  style={{
                    backgroundImage: `url(${REACT_APP_MEDIA_URL}${image || defaultImage})`
                    }}></div>
                
                {
                // delete image button
                image && (
                    <IconButton
                        onClick={(ev) => {
                            ev.stopPropagation();
                            this.setState({
                                confirmData: {
                                    prompt: "Delete the image completely?",
                                    onConfirm: async () => {
                                        if( await this.props.onDelete() ){
                                            this.setState({confirmData: null})
                                        }
                                    }

                                }
                            })
                        }}
                        className="image-selector-close"
                    >
                        <Delete />
                    </IconButton>
                )}

            </div>

            <ModalWindow open={modalOpen} onClose={() => this.setState({modalOpen: false})} 
            header="Select program image" 
            // formClassName="full-screen"
            >

                <p>Select an existing program image or Upload New Image </p>
                <ProgramImageSelect onSelect={this.onImageSelected} images={images} image={selected || image}/>

                {/* hidden input to select image to upload */}
                <input 
                type='file' 
                style={{display: "none"}} 
                id="image-file-input" 
                onChange={onSelect} 
                accept="image/*"
                />

                <div className='align-self-stretch flex justify-content-space-between row'>
                    <Button variant="contained" color="primary" disabled={!selected}
                    onClick={ this.onImageUpdate }
                    >Choose selected</Button>
                    <Button variant="contained" color="" 
                    onClick={ () => {
                        document.getElementById('image-file-input').click();
                    }}
                    >Upload new</Button>
                    <Button variant="contained" color="secondary"
                    onClick={()=>  this.setState({modalOpen: false})}
                    >Cancel</Button>
                </div>
            </ModalWindow>
            <ConfirmDialog
            open={Boolean(confirmData)}
            
            {...confirmData}
            
            // prompt={confirmData.prompt}
            // onConfirm={async () => {
            //     await onDelete();
            //     this.setState({ showConfirmDelete: false });
            // }}
            onClose={() => this.setState({ confirmData: null})}
            />
            </>
        )
    }
}

export default ProgramImageSelector