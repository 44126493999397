import React from 'react'
import Spinner from '../../../template/spinner';

const {REACT_APP_MEDIA_URL} = process.env;
class ProgramImageSelect extends React.Component{

    // state = {
    //     images: null
    // }

    render(){
        
        const {onSelect, images, image} = this.props;

        if( images === null ) return <Spinner />

        return(<ul className='image-list'>
            {images.map( i => <li className={image === i ? 'selected' : ''}>
                <div className='image-selector'>
                    <div className='display' 
                    style={{
                        backgroundImage: `url(${REACT_APP_MEDIA_URL}/programs/${i})`
                    }}
                    onClick={() => onSelect(i)}
                    ></div>
                </div>
            </li>)}
        </ul>)
    }
}

export default ProgramImageSelect